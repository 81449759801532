/* You can add global styles to this file, and also import other style files */
@import "app/core/scss/core";
    

.table {
    width: 100%;
    height: 100%;
}
  
html,
body {
  height: 100%;
  font-size: 14px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

#root {
  height: 100%;
}

.valid {
  input,
  textarea {
    border-color: green !important;
    box-shadow: none !important;
  }

  ng-select {
    border: 1px solid green;
    border-radius: 4px;
  }
}

.invalid {
  input,
  textarea {
    border-color: red !important;
    box-shadow: none !important;
  }

  ng-select {
    border: 1px solid red;
    border-radius: 4px;
  }
}

#MandatoryField{
  color: red;
}

.required {
  color: red;
}

.ptusManagementTable .mat-table {
	margin-right: 400px !important;
}

.ptuEditsLogTable .mat-header-cell.mat-column-ptu_status,
.ptuEditsLogTable .mat-column-ptu_status {
  position: initial !important;
	box-shadow: none !important;
}

// mat-dialog-container {
//   max-width: 50vw !important;
// }

.btn-excel {
  background-color: rgb(37, 115, 72) !important;
  border: rgb(37, 115, 72) !important;
}

.btn-secondary {
  background-color: rgb(108, 117, 125) !important;
  border: rgb(108, 117, 125) !important;
}